p {
  line-height: 2;
}

ul li {
  line-height: 2;
}

.container {
  padding-top: 30px;
  padding-bottom: 50px;
}